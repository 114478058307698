<template>
    <div class="relative pb-5 mx-3 mt-5 bg-white rounded-lg shadow-lg">
        <LoadingComponent :isLoading="loading" />
        <div class="m-5 px-[5%] py-[30px]">
            <div class="mb-5 text-2xl font-bold">形象首頁資訊</div>
            <el-form ref="formDom" :model="form" :rules="rules">
                <div class="flex">
                    <el-form-item prop="title" class="flex-1">
                        <label
                            >標題
                            <span
                                v-if="screenshotImage === null"
                                class="text-sm text-red-500"
                                >*</span
                            ></label
                        >
                        <el-input
                            v-model="form.title"
                            minlength="1"
                            maxlength="255"
                            placeholder="請輸入顯示標題"
                        ></el-input>
                    </el-form-item>
                    <el-form-item prop="description" class="flex-1 ml-5">
                        <label>描述</label>
                        <el-input
                            v-model="form.description"
                            type="textarea"
                            maxlength="255"
                            placeholder="請輸入描述內容"
                        ></el-input>
                    </el-form-item>
                </div>
                <div class="flex">
                    <el-form-item prop="url" class="flex-1">
                        <label
                            >媒體來源網址
                            <span
                                v-if="screenshotImage === null"
                                class="text-sm text-red-500"
                                >*</span
                            ></label
                        >
                        <el-input
                            v-model.trim="form.url"
                            minlength="1"
                            placeholder="ex:https://google.com"
                        ></el-input>
                    </el-form-item>
                    <el-form-item prop="sequence" class="flex-1 ml-5">
                        <label>排序</label>
                        <el-input
                            v-model.number="form.sequence"
                            type="number"
                        ></el-input>
                    </el-form-item>
                </div>
                <div class="flex">
                    <div class="relative flex-1">
                        <label
                            >小圖
                            <span
                                v-if="logoImage === null"
                                class="text-sm text-red-500"
                                >*</span
                            ></label
                        >
                        <Upload
                            :photoImage.sync="logoImage"
                            :photoFile.sync="logoFile"
                            :imageSizes="{ width: [100], height: [100] }"
                        />
                        <div
                            v-if="isErrorLogoImage && logoImage === null"
                            class="el-form-item__error"
                        >
                            請上傳小圖
                        </div>
                    </div>
                    <div class="relative flex-1 ml-5">
                        <label
                            >大圖
                            <span
                                v-if="screenshotImage === null"
                                class="text-sm text-red-500"
                                >*</span
                            ></label
                        >
                        <Upload
                            :photoImage.sync="screenshotImage"
                            :photoFile.sync="screenshotFile"
                            :imageSizes="{ width: [466], height: [194] }"
                        />
                        <div
                            v-if="
                                isErrorScreenshotImage &&
                                screenshotImage === null
                            "
                            class="el-form-item__error"
                        >
                            請上傳大圖
                        </div>
                    </div>
                </div>
            </el-form>
            <div class="mt-[100px] flex justify-center">
                <button
                    class="mr-5 black-btn-outline-lg text-md"
                    @click="onBack"
                >
                    返回列表
                </button>
                <button
                    v-permission="['create']"
                    class="orange-btn-800-lg text-md"
                    @click="onSubmit('formDom')"
                >
                    新增
                </button>
            </div>
        </div>
    </div>
</template>

<script >
import { ref, reactive, computed, onActivated, onMounted, watch, getCurrentInstance, defineExpose, onBeforeUnmount, nextTick } from "@vue/composition-api";
import { useRoute, useRouter } from "vue2-helpers/vue-router";
// Popup 訊息套件
import { Message } from "element-ui";
// 旋轉動畫組件
import LoadingComponent from "@/components/Loading.vue";
// 圖片上傳組件
import Upload from "@/components/ImageUpload.vue";
// 取得全域 this 方法 ex: this.$api, this.$message
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const router = useRouter();
  const {
    proxy
  } = getCurrentInstance();
  // 用來顯示客製化錯誤
  const isErrorLogoImage = ref(false);
  // 用來顯示客製化錯誤
  const isErrorScreenshotImage = ref(false);
  const loading = ref(false);
  // 圖片base64
  const logoImage = ref(null);
  // 圖片檔案
  const logoFile = ref(null);
  // 圖片base64
  const screenshotImage = ref(null);
  // 圖片檔案
  const screenshotFile = ref(null);
  // 表單資料
  const form = ref({});
  // 表單驗證規則
  const rules = ref({
    title: [{
      required: true,
      message: "請輸入標題",
      trigger: "blur"
    }, {
      max: 255,
      message: "標題最多250字",
      trigger: "blur"
    }],
    url: [{
      required: true,
      message: "資料來源網址為必填",
      trigger: "blur"
    }, {
      validator: validURL,
      trigger: "blur"
    }]
  });
  // 網址驗證
  function validURL(rule, value, callback) {
    var pattern = new RegExp("^(https?:\\/\\/)?" +
    // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
    // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" +
    // OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
    // port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" +
    // query string
    "(\\#[-a-z\\d_]*)?$", "i"); // fragment locator

    if (!pattern.test(value)) {
      callback(new Error("請輸入正確網址"));
    } else {
      callback();
    }
  }
  // 返回
  function onBack() {
    router.push({
      name: "user_experience"
    });
  }

  /**
   * 表單發送
   * @param { type String(字串) } formName 傳送表單名稱
   * @example ref="form"
   */
  async function onSubmit(formName) {
    // 檢查小圖是否上傳
    if (logoImage.value === null) {
      isErrorLogoImage.value = true;
    } else {
      isErrorLogoImage.value = false;
    }
    // 檢查大圖是否上傳
    if (screenshotImage.value === null) {
      isErrorScreenshotImage.value = true;
    } else {
      isErrorScreenshotImage.value = false;
    }
    try {
      // 等待表單驗證是否成功 try catch 會自動判斷是 true 或 false 因次不用寫 if 判斷
      await this.$refs[formName].validate();
      isErrorLogoImage.value = false;
      isErrorScreenshotImage.value = false;
      await create(form.value);
    } catch (err) {
      console.log(err);
      Message({
        type: "error",
        message: "表單驗證失敗"
      });
    }
  }

  /**
   * 新增
   */
  async function create() {
    loading.value = true;
    const result = new FormData();
    Object.keys(form.value).forEach(objKey => {
      result.append(objKey, form.value[objKey]);
    });
    result.append("logo", logoFile.value);
    result.append("screenshot", screenshotFile.value);
    try {
      await proxy.$api.CreateUserExperienceApi(result);
      Message({
        type: "success",
        message: "新增成功"
      });
      router.push({
        name: "user_experience"
      });
    } catch (err) {
      Message({
        type: "error",
        message: "新增失敗"
      });
    } finally {
      loading.value = false;
    }
  }
  return {
    isErrorLogoImage,
    isErrorScreenshotImage,
    loading,
    logoImage,
    logoFile,
    screenshotImage,
    screenshotFile,
    form,
    rules,
    onBack,
    onSubmit
  };
};
__sfc_main.components = Object.assign({
  LoadingComponent,
  Upload
}, __sfc_main.components);
export default __sfc_main;
</script>
